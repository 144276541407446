<template>
  <div id="app" class="bg-light">
    <transition :duration="{ enter: 250, leave: 150 }"
        enter-active-class="animated fadeIn faster"
        leave-active-class="animated fadeOut faster" 
        mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
 
<script>
  export default {
    name: 'App', 
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'VitaCare',
      // all titles will be injected into this template
      titleTemplate: '%s - Prestadores',
      htmlAttrs: {
        lang: 'en-US'
      },
      meta: [
        // { charset: 'utf-8' },
        { name: 'description', content: 'An example Vue application with vue-meta.' },
        // { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    },
    data() {
      return {
        
      }
    }
  }
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import 'bootstrap/dist/css/bootstrap.css';
@import 'bootstrap-vue/dist/bootstrap-vue.css';
@import './assets/css/font-awesome.css';
@import './assets/css/animate_3.7.2.min.css';

body {
  background-color: #ffffff !important;
  font-family: 'Montserrat', sans-serif !important;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.cursor-pointer {
  cursor: pointer;
}
.top-nav {
  background-color: #ffffff;
}
.nav-link {
  color: #7b829c !important;
  text-decoration: none!important;
}
</style>
