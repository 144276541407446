import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import * as VueGoogleMaps from 'vue2-google-maps'

// check src/client/node_modules/bootstrap-vue/src/index.js
import { 
  LayoutPlugin,
  FormGroupPlugin,
  FormPlugin,
  ButtonPlugin,
  FormInputPlugin,
  // TablePlugin,
  ModalPlugin,
  FormSelectPlugin,
  SpinnerPlugin,
  InputGroupPlugin,
  ToastPlugin,
  PaginationPlugin,
  FormCheckboxPlugin,
  TabsPlugin,
  CardPlugin,
  TablePlugin,
  FormTextareaPlugin,
  CollapsePlugin,
  AlertPlugin,
  
} from 'bootstrap-vue'


Vue.config.productionTip = false

Vue.use(VueMeta)
// check src/client/node_modules/bootstrap-vue/src/index.js
// other elements that you may need
// check src/client/node_modules/bootstrap-vue/src/index.js
// other elements that you may need
Vue.use(LayoutPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(CollapsePlugin)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(CardPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormInputPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormSelectPlugin)
Vue.use(ModalPlugin)
// Vue.use(TablePlugin)
Vue.use(FormPlugin)
Vue.use(SpinnerPlugin)
Vue.use(InputGroupPlugin)
Vue.use(ToastPlugin)
Vue.use(PaginationPlugin)
Vue.use(AlertPlugin)

Vue.use(VueGoogleMaps, {
  load: {
      key: process.env.VUE_APP_API_GMAPS_KEY,
      libraries: [/* rest of libraries */]
  }
})

// Vue.http.interceptors.push(function (request, next) {
//   if (!request.headers.has('Authorization') && window.localStorage.getItem('token') !== null) {
//     request.headers.set('Authorization', window.localStorage.token);
//   }
//   next()
// });

let globalData = new Vue({
  data: { $user: JSON.parse(localStorage.getItem('user')) }
});
Vue.mixin({
  computed: {
      $user: {
          get: function () { return globalData.$data.$user },
          set: function (new_val) { globalData.$data.$user = new_val; }
      }
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
