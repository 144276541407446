import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: { name: 'apply' } },
  {
    path: '/apply',
    name: 'apply',
    component: () => import(/* webpackChunkName: "apply" */ '../pages/ApplyView.vue')
  },
  { path: '*', redirect: { name: 'apply' } },
  // {
  //   path: '/chat/:room_id/:passcode',
  //   name: 'chat',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../pages/ChatView.vue')
  // }
]

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  base: process.env.VUE_APP_BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
//   if(to.name == 'home' && to.params.page == undefined) {
//     next({name: 'home', params: {page: 1}});
//     return;
//   }
// 	next();
// });


export default router
